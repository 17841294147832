import type { ComparableConfigurations, contract, DemandDriver, DistributionGroup, ProfileDetails, Section, SimulatingPostTGEPop, SubSection } from '@forgd/contract'
import type { ClientInferRequest, ClientInferResponseBody } from '@ts-rest/core'
import { referenceProjects } from '@forgd/contract'

import { watchDeep } from '@vueuse/core'
// we need to get the surrounding pages using vue-router
import { withBase } from 'ufo'
import type { PercentageEmissions, PercentageEmissionsResponse } from '~/server/routes/calculator/percentage-emissions.post'

export type GetDashboardResponse = ClientInferResponseBody<typeof contract.tokenDesigner.getDashboard>
export type GetTokenDesignerResponse = ClientInferResponseBody<typeof contract.tokenDesigner.getTD>
export type GetTokenProfileResponse = ClientInferResponseBody<typeof contract.tokenDesigner.getTDTokenProfile>
export type GetModelingResponse = ClientInferResponseBody<typeof contract.tokenDesigner.getTDModeling>
export type GetValuationResponse = ClientInferResponseBody<typeof contract.tokenDesignerValuation.getTDValuation>
export type PutAdjustingRequest = ClientInferRequest<typeof contract.tokenDesigner.putAdjusting>
export type PutAdjustingResponse = ClientInferResponseBody<typeof contract.tokenDesigner.putAdjusting>
export type PutValuationRequest = ClientInferRequest<typeof contract.tokenDesignerValuation.putTDValuation>
export type PutValuationResponse = ClientInferResponseBody<typeof contract.tokenDesignerValuation.putTDValuation>
export type PutTokenProfileRequest = ClientInferRequest<typeof contract.tokenDesigner.putTDTokenProfile>
export type PutTokenProfileResponse = ClientInferResponseBody<typeof contract.tokenDesigner.putTDTokenProfile>
export type PutModelingRequest = ClientInferRequest<typeof contract.tokenDesigner.putTDModeling>
export type PutModelingResponse = ClientInferResponseBody<typeof contract.tokenDesigner.putTDModeling>

export type DistributionGroupWithID = DistributionGroup & { id: NonNullable<DistributionGroup['id']> }
export type EmissionType = 'lock-and-vest' | 'epoch-based' | 'full-initial-unlock'

// reactivity bug with pinia
export const tdSections = ref<Array<Section>>([])

export function updateSections(_sections: Array<Section>) {
  const enhancedSections = [..._sections]
  for (const sk in enhancedSections) {
    for (const ssk in enhancedSections[sk].subSections) {
      enhancedSections[sk].subSections[ssk] = {
        ...enhancedSections[sk].subSections[ssk],
        ...TokenDesignerSectionMeta[enhancedSections[sk].slug][enhancedSections[sk].subSections[ssk].slug],
      }
    }
  }
  tdSections.value = enhancedSections
}

const TokenDesignerSectionMeta = {
  'token-profile': {
    'basic-information': {
      weight: 0.1,
      description: 'Tell us about your project.',
      resources: [
        {
          link: '/pathfinder/articles/build-tokenomics-and-protocol-value-flows/tokenomics-101',
          title: 'Tokenomics 101',
          type: 'Pathfinder',
        },
      ],
    },
    'business-model': {
      weight: 0.2,
      description: `The business model is intrinsically linked to your project's growth
          and evolution. Growth is simply another word for value capture. In the
          previous step, you defined what value is for your project; here, we
          are now thinking about how you capture some of this value, aka your
          business model, and to whom value gets distributed.`,
      resources: [
        {
          link: '/pathfinder/articles/build-tokenomics-and-protocol-value-flows/designing-the-core-business#business-model',
          title: 'Business Model',
          type: 'Pathfinder',
        },
      ],
    },
    'product-market-fit': {
      weight: 0.2,
      description: `Complete an "ecosystem assessment" for your project to establish product
        market fit. Establish who your users are, what tasks they perform, their
        motivations, the value they create, and the incentives you can offer
        them to catalyze more of this behavior. Once completed, update the
        status indicator to "Complete" and continue with the Token Designer.`,
      resources: [
        {
          link: '/pathfinder/articles/build-tokenomics-and-protocol-value-flows/finding-product-market-fit',
          title: 'Finding Product Market Fit',
          type: 'Pathfinder',
        },
      ],
    },
    'mission-vision': {
      weight: 0.1,
      description: 'Whereas the problem and your solution often allude to a single product, your mission and vision are an opportunity to extrapolate further and discuss the overarching business model of your blockchain project and your aspirational future state.',
      resources: [
        {
          link: '/pathfinder/articles/build-tokenomics-and-protocol-value-flows/designing-the-core-business#mission-vision',
          title: 'Mission & Vision',
          type: 'Pathfinder',
        },
      ],
    },
    'problem-and-solution': {
      weight: 0.1,
      description: `Having a clear understanding of the problem at hand is a must. You may
        not be able to describe your solution yet, this is normal, simply having
        an idea at this point is good enough. Throughout the process, you will
        be exploring and developing this idea.`,
      resources: [
        {
          link: '/pathfinder/articles/build-tokenomics-and-protocol-value-flows/designing-the-core-business#problem-solution',
          title: 'Problem & Solution',
          type: 'Pathfinder',
        },
      ],
    },
    'value-capture': {
      weight: 0.2,
      description: 'Value capture relates to how a project captures the value it creates. Use comparable projects to ideate and brainstorm how you intend to capture value.',
      resources: [
        {
          link: '/pathfinder/articles/build-tokenomics-and-protocol-value-flows/value-flows-and-feedback-loops#token-value-capture',
          title: 'Token Value Capture',
          type: 'Pathfinder',
        },
      ],
    },
    'value-creation': {
      weight: 0.1,
      description: `Whenever you solve a problem successfully, you create value for
          someone. Successful projects consistently solve their users' problems.
          Think about how you create value.`,
      resources: [
        {
          link: '/pathfinder/articles/build-tokenomics-and-protocol-value-flows/designing-the-core-business#value-proposition-value-creation',
          title: 'Value Proposition & Value Creation',
          type: 'Pathfinder',
        },
      ],
    },
  },
  'valuation-documenting': {
    'fdv-maximum-token-supply': {
      description: 'Determining the valuation of a blockchain project is notoriously difficult. Valuation determines the worth or “fair value” of a token, project, asset, or company. Comparing with other projects can help with this process.',
      resources: [
        {
          link: '/pathfinder/articles/build-tokenomics-and-protocol-value-flows/determining-your-valuation',
          title: 'Fully Diluted Valuation',
          type: 'Pathfinder',
        },
      ],
    },
    'sanitizing-tokenomics': {
      title: 'Create Project Summary',
      description: 'Documenting what you\'ve accomplished thus far will be helpful as you begin to scale your community and reach out to investors, market makers, and exchanges. Create and publish and internal and external facing public page to showcase what you have designed so far.',
      resources: [
        {
          link: '/pathfinder/articles/build-tokenomics-and-protocol-value-flows/memorializing-the-vision',
          title: 'Memorializing the Vision',
          type: 'Pathfinder',
        },
      ],
    },
  },
  'modeling': {
    'demand-drivers': {
      description: 'Thinking about who will buy your token is as important as distributing it. Oversupplying without matching demand can lead to price depreciation.',
      resources: [
        {
          link: '/pathfinder/articles/build-tokenomics-and-protocol-value-flows/value-flows-and-feedback-loops#demand-drivers',
          title: 'Demand Drivers',
          type: 'Pathfinder',
        },
      ],
    },
    'estimating-demand': {
      description: `<p class="mb-3">Quantify your demand drivers. Leverage templates, enter monthly demand manually, or work out the math in a spreadsheet. Demand can be denominated in "US Dollars" or " Quantity of Tokens".</p>
        <p>"Activate" or "Deactivate" your demand drivers to include it in the modeling for future token performance.</p>`,
      resources: [
        {
          // TODO replace with proper resource
          link: '/pathfinder/articles/build-tokenomics-and-protocol-value-flows/value-flows-and-feedback-loops#demand-drivers',
          title: 'Demand Drivers',
          type: 'Pathfinder',
        },
      ],
    },
    'token-distribution-schedule': {
      description: `A token’s Distribution Schedule dictates the issuance of tokens to
        various individuals and stakeholders within a protocol. Use our
        recommended distribution or references from other projects to determine
        what is most suitable for you.`,
      resources: [
        {
          link: '/pathfinder/articles/build-tokenomics-and-protocol-value-flows/value-flows-and-feedback-loops#token-distribution-schedule',
          title: 'Token Distribution Schedule',
          type: 'Pathfinder',
        },
      ],
    },
    'token-emission-schedule': {
      description: 'A token’s Emissions Schedule determines the manner and rate at which tokens enter circulation. As tokens enter circulation, the supply of tokens available to the public increases; more available tokens mean more buyers are required to keep the price stable.',
      resources: [
        {
          link: '/pathfinder/articles/build-tokenomics-and-protocol-value-flows/value-flows-and-feedback-loops#token-emissions-schedule',
          title: 'Token Emission Schedule',
          type: 'Pathfinder',
        },
      ],
    },
  },
  'adjusting': {
    'modeling-supply-and-demand': {
      description: `Evaluate your token's supply & demand. Make adjustments if your supply is significantly outpacing demand.`,
      resources: [
        {
          link: '/pathfinder/articles/plan-my-token-launch/price-discovery-in-the-secondary-market#price-is-a-function-of-supply-demand',
          title: 'Modelling Supply and Demand',
          type: 'Pathfinder',
        },
      ],
    },
    'modeling-sell-pressure': {
      description: 'Review your Token Distribution Schedule from the perspective of potential sell pressure. Combining this with a token price will help understand how many tokens in dollar terms will likely be sold in a given month.',
      resources: [
        {
          link: '/pathfinder/articles/plan-my-token-launch/price-discovery-in-the-secondary-market#why-you-need-to-care-about-price-discovery',
          title: 'Modeling Sell Pressure',
          type: 'Pathfinder',
        },
      ],
    },
    'optimizing-circulating-supply': {
      description: 'Review your circulating supply at TGE and projected Market Capitalization. Review the growth rate of your circulating supply.',
      resources: [
        {
          link: '/pathfinder/articles/plan-my-token-launch/price-discovery-in-the-secondary-market#token-emissions-price-equilibrium',
          title: 'Optimizing Circulating Supply',
          type: 'Pathfinder',
        },
      ],
    },
    'simulating-post-tge-pops': {
      description: `Simulate a post-TGE "pop" and review the impact on your token's short-term price performance, supply and demand, and the long-term impact on price and market cap.`,
      resources: [
        {
          link: '/pathfinder/articles/plan-my-token-launch/strategic-launch-mechanisms',
          title: 'Strategic Launch Mechanisms',
          type: 'Pathfinder',
        },
      ],
    },
    'simulating-price-discovery': {
      description: `Price discovery of your token is simulated by starting from the estimated monthly buy and sell pressure; the delta between the USD supply and demand on a given day is converted to net sell and buy pressure, which is then used to calculate the impact on the daily token price.`,
      resources: [
        {
          link: '/pathfinder/articles/plan-my-token-launch/price-discovery-in-the-secondary-market',
          title: 'Simulating Price Discovery',
          type: 'Pathfinder',
        },
      ],
    },
  },
}

export const useTD = defineStore('token-designer', () => {
  const auth = useAuth()
  const client = useClient()
  const toast = useAppToast()
  const { publicPageDomain } = useRuntimeConfig().public

  const route = useRoute()
  const router = useRouter()
  const currentSubMenu = ref()

  const tokenProfileDetails = computed<ProfileDetails>(() => {
    const tokenProfile = tdSections.value?.find(s => s.slug === 'token-profile')
    const basicInformation = tokenProfile?.subSections?.find(f => f.slug === 'basic-information')
    const profileDetails = basicInformation?.fields.find(f => f.slug === 'profile-details')
    const values = profileDetails?.values as ProfileDetails
    return {
      name: values?.name || auth.project?.name || '',
      ticker: values?.ticker || auth.project?.ticker || '',
      website: values?.website || auth.project?.link || '',
      image: values?.image || auth.project?.image || '',
    }
  })

  const context = computed(() => {
    // pinia reactivity works with useRouter, but not useRoute ¯\_(ツ)_/¯
    const path = currentQuickAdjust.value?.path || router.currentRoute.value.path
    const quickAdjust = tokenDesigner.value?.sections.find((s: Section) => s.slug === currentQuickAdjust.value?.sectionSlug)?.subSections.find((ss: SubSection) => ss.slug === currentQuickAdjust.value?.subSectionSlug)
    const [sectionSlug, subSectionSlug] = path.split('/').slice(-2)
    const section = tdSections.value.find((s: Section) => s.slug === sectionSlug)
    const subSection = section?.subSections.find(
      s => s.slug === subSectionSlug,
    )

    const subSectionMeta = TokenDesignerSectionMeta[sectionSlug]?.[subSectionSlug]

    return {
      tokenProfileDetails: tokenProfileDetails.value,
      currentSection: section ?? null,
      currentSubSection: subSection ?? null,
      subSectionMeta: subSectionMeta ?? null,
      currentQuickAdjust: quickAdjust ?? null,
      fields(slug: string) {
        return subSection?.fields.find(f => f.slug === slug)?.values
      },
    }
  })

  const adjusting = ref<Section>()
  const dashboard = ref<GetDashboardResponse>()
  const modeling = ref<Section>()
  const tokenDesigner = ref<GetTokenDesignerResponse>()
  const tokenProfile = ref<Section>()
  const valuation = ref<Section>()

  const nav = computed(() => {
    const items = [
      {
        label: 'Token Profile',
        to: 'token-profile',
        children: [
          {
            label: 'Basic Information',
            to: 'basic-information',
          },
          {
            label: 'Problem & Solution',
            to: 'problem-and-solution',
          },
          {
            label: 'Mission & Vision',
            to: 'mission-vision',
          },
          {
            label: 'Value Creation',
            to: 'value-creation',
          },
          {
            label: 'Business Model',
            to: 'business-model',
          },
          {
            label: 'Finding Product Market Fit',
            to: 'product-market-fit',
          },
          {
            label: 'Value Capture',
            to: 'value-capture',
          },
        ],
      },
      {
        label: 'Modeling',
        to: 'modeling',
        children: [
          {
            label: 'Token Distribution Schedule',
            to: 'token-distribution-schedule',
          },
          {
            label: 'Token Emission Schedule',
            to: 'token-emission-schedule',
          },
          {
            label: 'Demand Drivers',
            to: 'demand-drivers',
          },
          {
            label: 'Estimating Demand',
            to: 'estimating-demand',
          },
        ],
      },
      {
        label: 'Valuation',
        to: 'valuation-documenting',
        children: [
          {
            label: 'FDV, Maximum Token Supply',
            to: 'fdv-maximum-token-supply',
          },
        ],
      },
      {
        label: 'Performance Simulations',
        to: 'adjusting',
        children: [
          {
            label: 'Modeling Supply & Demand',
            to: 'modeling-supply-and-demand',
          },
          {
            label: 'Optimizing Circulating Supply',
            to: 'optimizing-circulating-supply',
          },
          {
            label: 'Modeling Sell Pressure',
            to: 'modeling-sell-pressure',
          },
          {
            label: 'Simulating Price Discovery',
            to: 'simulating-price-discovery',
          },
          {
            label: 'Simulating post-TGE Pops',
            to: 'simulating-post-tge-pops',
          },
        ],
      },
      {
        label: 'Publish',
        to: 'valuation-documenting',
        children: [
          {
            label: 'Create Project Summary',
            to: 'sanitizing-tokenomics',
          },
        ],
      },
      // {
      //   label: 'Plan Token Launch',
      //   to: 'plan-token-launch',
      //   children: [
      //     {
      //       label: 'Measuring Liquidity & Market Impact',
      //       to: 'measuring-liquidity-market-impact',
      //     },
      //     {
      //       label: 'Comparable Project Analysis',
      //       to: 'comparable-project-analysis',
      //     },
      //     {
      //       label: 'Simulating Price Discovery Post-TGE',
      //       to: 'simulating-price-discovery-post-tge',
      //     },
      //     {
      //       label: 'Simulating Market Sentiment Impact on Price Discovery',
      //       to: 'simulating-market-sentiment-impact',
      //     },
      //     {
      //       label: 'Performance Summary',
      //       to: 'performance-summary',
      //     },
      //   ],
      // },
    ]
    for (const item of items) {
      const slug = item.to
      item.to = withBase(item.to, '/token-designer/')
      if (item.children) {
        item.children = item.children.map((child) => {
          // check if the subsection is completed
          const section = tdSections.value.find(section => section.slug === slug)
          const subsection = section?.subSections.find(subSection => subSection.slug === child.to)
          return {
            ...child,
            to: withBase(child.to, item.to),
            icon: subsection?.completed ? 'i-heroicons-check-circle-solid' : 'i-heroicons-check-circle',
            completed: subsection?.completed,
          }
        })
      }
      if (currentSubMenu.value === item.to) {
        item.defaultOpen = true
      }
    }
    return items
  })

  watch(() => route.path, () => {
    // force re-render the accordion when route changes so we can show active menu section
    currentSubMenu.value = nav.value.find(item => route.path.includes(item.to))?.to
  }, {
    immediate: true,
  })

  /**
   * This watcher is used to detect when a section is completed
   */
  watchDeep(tdSections, (newValue, oldValue) => {
    const newSections = newValue.filter(section => section.completed)
    const oldSections = oldValue.filter(section => section.completed)

    // This is an initial load of state from the API, ignore
    if (oldSections.length === 0) {
      return
    }

    if (newSections.length > oldSections.length && oldSections.length > 0) {
      const changedSection = newSections.find((section) => {
        return !oldSections.find(oldSection => oldSection.slug === section.slug)
      })

      if (changedSection?.completed) {
        toast.success({
          title: 'Congratulations!',
          description: `You have completed the “${changedSection.label}” section of Token Designer successfully. Great progress.`,
          icon: 'i-heroicons-flag',
          ui: {
            icon: {
              base: 'w-5 h-5',
            },
          },
          color: 'blue',
        })
      }
    }
  })

  async function fetchTD() {
    if (!auth.project?.id) {
      return
    }

    const res = await client.tokenDesigner.getTD({
      params: { projectId: auth.project?.id },
    })

    if (res.status === 200) {
      tokenDesigner.value = res.body
      updateSections(res.body.sections)
      setInitialState(res.body)
      return res.body
    }
  }

  async function fetchDashboard() {
    if (!auth.project?.id) {
      return
    }

    const res = await client.tokenDesigner.getDashboard({
      params: { projectId: auth.project?.id },
    })

    if (res.status === 200) {
      dashboard.value = res.body
      return res.body
    }
  }

  async function fetchModeling(preview: boolean = true) {
    if (!auth.project?.id) {
      return
    }

    const res = await client.tokenDesigner.getTDModeling({
      params: {
        projectId: auth.project?.id,
      },
      query: {
        preview: preview ? 'true' : 'false',
      },
    })

    if (res.status === 200) {
      modeling.value = res.body.section
      return res.body
    }
  }

  async function fetchTokenProfile() {
    if (!auth.project?.id) {
      return
    }

    const res = await client.tokenDesigner.getTDTokenProfile({
      params: { projectId: auth.project?.id },
    })

    if (res.status === 200) {
      tokenProfile.value = res.body
      // sync sections, triggers reactivity
      updateSections(tdSections.value.map((s) => {
        if (s.slug === 'token-profile') {
          return res.body
        }
        return s
      }))
      return res.body
    }
  }

  async function fetchValuation() {
    if (!auth.project?.id) {
      return
    }

    const res = await client.tokenDesignerValuation.getTDValuation({
      params: { projectId: auth.project?.id },
    })

    if (res.status === 200) {
      valuation.value = res.body
      return res.body
    }
  }

  const demandDrivers = computed(() => getDemandDrivers())
  const distributionSchedule = computed(() => getDistribution())
  const fdvMaxTokenSupply = computed(() => getFDV())
  const monthlyEmissions = ref<PercentageEmissions>()
  const postTgePop = computed(() => getPostTGEPop())

  function getDistribution() {
    const subsection = modeling?.value?.subSections.find(
      s => s.slug === 'token-distribution-schedule',
    )
    const field = subsection?.fields.find(f => f.slug === 'groups')
    const values = field?.values as Array<DistributionGroup>
    return values
  }

  function getDemandDrivers() {
    const subsection = modeling?.value?.subSections.find(
      s => s.slug === 'demand-drivers',
    )
    const field = subsection?.fields.find(f => f.slug === 'token-demand-drivers')
    const values = field?.values as Array<DemandDriver>
    return values
  }

  function getPostTGEPop() {
    const subsection = adjusting?.value?.subSections.find(
      s => s.slug === 'simulating-post-tge-pops',
    )
    const field = subsection?.fields.find(f => f.slug === 'simulating-a-post-tge-pop')
    const values = field?.values
    return values as SimulatingPostTGEPop
  }

  async function fetchMonthlyEmissionsPreview(groups: Array<DistributionGroup>) {
    const res = await $fetch<PercentageEmissionsResponse>('/calculator/percentage-emissions', {
      method: 'POST',
      body: {
        groups,
        total_supply: fdvMaxTokenSupply.value.configurations.MTS,
      },
    })
    return res.emissions
  }

  async function fetchMonthlyEmissions() {
    const groups = getDistribution()
    monthlyEmissions.value = await fetchMonthlyEmissionsPreview(groups)
  }

  function getFDV() {
    const subsection = valuation?.value?.subSections.find(
      s => s.slug === 'fdv-maximum-token-supply',
    )
    const field = subsection?.fields.find(f => f.slug === 'comparables-configurations')
    const values = field?.values as ComparableConfigurations
    return values
  }

  async function putModeling(req: PutModelingRequest) {
    if (!auth.project?.id) {
      return
    }

    const res = await client.tokenDesigner.putTDModeling(req)
    if (res.status === 201) {
      const body = res.body as PutModelingResponse
      updateSections(body.tokenDesigner.sections)
      modeling.value = body.tokenDesigner.sections.find((s: Section) => s.slug === 'modeling')

      await Promise.all([
        fetchDashboard(), // refresh the dashboard data to update the TdEstimatedDemand
        fetchMonthlyEmissions(), // refresh the emission schedule chart data
      ])
    }
    return res
  }

  async function putTokenProfile(req: PutTokenProfileRequest) {
    if (!auth.project?.id) {
      return
    }

    const res = await client.tokenDesigner.putTDTokenProfile(req)

    if (res.status === 201) {
      // TODO this should return a token profile ? returns entire TD atm
      const body = res.body as PutTokenProfileResponse
      updateSections(body.sections)
      tokenProfile.value = body.sections.find((s: Section) => s.slug === 'token-profile')
    }
    return res
  }

  async function putValuation(req: PutValuationRequest) {
    if (!auth.project?.id) {
      return
    }

    const res = await client.tokenDesignerValuation.putTDValuation(req)
    if (res.status === 201) {
      const body = res.body as PutValuationResponse
      updateSections(body.tokenDesigner.sections)
      valuation.value = body.tokenDesigner.sections.find((s: Section) => s.slug === 'valuation-documenting')
    }
    return res
  }

  async function putAdjusting(req: PutAdjustingRequest) {
    if (!auth.project?.id) {
      return
    }

    const res = await client.tokenDesigner.putAdjusting(req)
    // yes, just spent an hour debugging the fact this returns a 200 unlike other PUT endpoints
    if (res.status === 200) {
      const body = res.body as PutAdjustingResponse
      updateSections(body.tokenDesigner.sections)
      adjusting.value = body.tokenDesigner.sections.find((s: Section) => s.slug === 'adjusting')
    }
    return res
  }

  async function loadTemplate(template: 'defi' | 'depin' | 'base') {
    if (!auth.project?.id) {
      return
    }

    const res = await client.tokenDesigner.loadTD({
      params: { projectId: auth.project?.id },
      body: {
        template,
      },
    })

    if (res.status === 200) {
      tokenDesigner.value = res.body
      updateSections(res.body.sections)
      setInitialState(res.body)
      return res.body
    }
  }

  const selectedTemplate = computed(() => tokenDesigner.value?.template)

  // takes a full token designer object from fetchTD or loadTemplate
  function setInitialState(td: GetTokenDesignerResponse) {
    tokenDesigner.value = td
    valuation.value = td.sections.find((s: Section) => s.slug === 'valuation-documenting')
    modeling.value = td.sections.find((s: Section) => s.slug === 'modeling')
    tokenProfile.value = td.sections.find((s: Section) => s.slug === 'token-profile')
    adjusting.value = td.sections.find((s: Section) => s.slug === 'adjusting')
  }

  const publicPageUrl = computed(() => {
    return `https://${publicPageDomain}/share/${auth.ticker?.toLowerCase()}/${auth.project?.id}/public`
  })

  const currentQuickAdjust = ref<QuickAdjust | null>(null)

  function $reset() {
    tokenDesigner.value = undefined
    dashboard.value = undefined
    modeling.value = undefined
    tokenProfile.value = undefined
    valuation.value = undefined
    monthlyEmissions.value = undefined
    adjusting.value = undefined
  }

  return {
    tokenDesigner,
    adjusting,
    currentQuickAdjust,
    currentSubMenu,
    context,
    dashboard,
    demandDrivers,
    distributionSchedule,
    fdvMaxTokenSupply,
    getDistribution,
    getFDV,
    fetchDashboard,
    fetchModeling,
    fetchMonthlyEmissions,
    fetchMonthlyEmissionsPreview,
    fetchTD,
    fetchTokenProfile,
    fetchValuation,
    loadTemplate,
    monthlyEmissions,
    nav,
    postTgePop,
    publicPageUrl,
    putAdjusting,
    putModeling,
    putTokenProfile,
    putValuation,
    referenceProjects,
    selectedTemplate,
    $reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTD, import.meta.hot))
}
